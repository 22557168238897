function getPosts() {
    return (function () {
        let posts = [];
        $.ajax({
            async: false,
            url: '/json/posts.json',
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data) {
                    posts = data;
                }
            }
        });
        return posts;
    })();
}


$(document).ready(function () {

    // RESSOURCE-CENTER PAGE
    if ($('.posts-search').length) {
        //
        const posts = getPosts();
        //

        function filterPosts(posts, selectedContentTypes, selectedIndustries, selectedTopics, selectedLanguage, searchQuery) {
            return posts.filter(function (post) {
                var filters = post.filters;

                var contentTypeMatch = selectedContentTypes.length === 0 || selectedContentTypes.includes(filters.contentType);
                var industryMatch = selectedIndustries.length === 0 || selectedIndustries.includes(filters.industry);
                var topicMatch = selectedTopics.length === 0 || selectedTopics.includes(filters.topic);
                var languageMatch = selectedLanguage.length === 0 || selectedLanguage.includes(filters.language);
                var titleMatch = post.title.toLowerCase().includes(searchQuery.toLowerCase());

                return languageMatch && contentTypeMatch && industryMatch && topicMatch && titleMatch;
            });
        }

        //
        function handleFiltersChange() {

            var selectedContentTypes = $('.contentType:checked').map(function () {
                return $(this).val();
            }).get();

            var selectedIndustries = $('.industry:checked').map(function () {
                return $(this).val();
            }).get();

            var selectedTopics = $('.topic:checked').map(function () {
                return $(this).val();
            }).get();

            var selectedLanguage = $('.language:checked').map(function () {
                return $(this).val();
            }).get();

            var searchQuery = $('#search-posts').val();

            // Фильтруем посты на основе выбранных параметров
            var filteredPosts = filterPosts(posts, selectedContentTypes, selectedIndustries, selectedTopics, selectedLanguage, searchQuery);

            // Обновляем отображение постов на странице
            renderPosts(filteredPosts);
        }

        //
        function renderPosts(filteredPosts) {
            let html = '';
            if (filteredPosts.length > 0) {
                for (let i = 0; i < filteredPosts.length; i++) {
                    html += `
                    <li class="post-item">
                        <div class="post-image">
                            <img src="${filteredPosts[i].mainImage}" alt="Post image">
                        </div>
                        <div class="post-content">
                            <h5 class="post-title">${filteredPosts[i].title}</h5>
                            <p class="post-text">${filteredPosts[i].subtitle}</p>
                            <a href="${filteredPosts[i].link}" class="post-link btn btn-secondary">Find out more</a>
                        </div>
                    </li>`;
                }
                ;
            } else {
                html = `<li class="empty">Nothing found</li>`
            }

            $('.section-posts .posts-list').html(html);

            if ($(".post-item").length <= 6) {
                $("#loadMoreBtn").hide();
            } else {
                $("#loadMoreBtn").show();
            }
            // 
            $(".post-item").hide();
            $(".post-item").slice(0, 6).show();
            $("#loadMoreBtn").on("click", function (e) {
                e.preventDefault();
                $(".post-item:hidden").slice(0, 6).slideDown();
                if ($(".post-item:hidden").length == 0) {
                    $("#loadMoreBtn").hide();
                }
            });
        }

        //
        $('.js-btn-clear').on('click', function () {
            // Сброс значений фильтров
            $('.industry, .contentType, .topic').prop('checked', false);
            $('#search-posts').val('');

            handleFiltersChange();
        });
        // 
        handleFiltersChange();
        $('.contentType, .industry, .topic, .language, #search-posts').on('change keyup', handleFiltersChange);
    }
    // POST PAGE
    if ($('.section-post-content').length) {
        //
        const posts = getPosts();
        //
        // SECTION HERO
        function renderHeroSection(posts) {
            let currentUrl = window.location.href;
            let html = '';
            for (let i = 0; i < posts.length; i++) {
                let regex = new RegExp(`post-${posts[i].postId}`);
                var isMatch = regex.test(currentUrl);
                if (isMatch) {
                    html = `
                    <div class="section-wrapper" style="background-image: url(../${posts[i].mainImage});">
                        <div class="container">
                            <h1 class="section-title">${posts[i].title}</h1>
                            <p class="section-subtitle">
                                By ${posts[i].author}
                                <br>
                                <span>${posts[i].date}</span>
                            </p>
                        </div>
                    </div>`
                }
            }
            ;

            $('.post .section-hero').html(html);
        }

        renderHeroSection(posts)

        // POSTS FOR SIDEBAR
        function renderSidePosts(posts, postsContainer) {
            let html = '';
            for (let i = 0; i < 9; i++) {
                html += `
                <li class="post-item" >
                    <div class="post-image">
                        <img src="../${posts[i].mainImage}" alt="Post image">
                    </div>
                    <div class="post-content">
                        <h5 class="post-title">
                            <a href="${posts[i].link}">
                                ${posts[i].title}
                            </a>
                        </h5>
                        <p class="post-date">${posts[i].date}</p>
                    </div>
                </li> `;
            }
            ;
            $(postsContainer).html(html);
        }

        renderSidePosts(posts.sort((a, b) => new Date(b.date) - new Date(a.date)), '.recent-posts-list');
        renderSidePosts(posts.sort((a, b) => b.rating - a.rating), '.popular-posts-list');

        // POSTS FOR RELATED TOPICS
        function renderRelatedPosts(posts, postsContainer) {
            let html = '';
            for (let i = 0; i < posts.length; i++) {
                if (i >= 3) {
                    break
                } else {
                    html += `
                        <li class="post-item">
                        <div class="post-image">
                            <img src="../${posts[i].mainImage}" alt="Post image">
                        </div>
                        <div class="post-content">
                            <h5 class="post-title">
                                <a href="${posts[i].link}">
                                    ${posts[i].title}
                                </a>
                            </h5>
                        </div>
                    </li> `;
                }
            }
            ;
            $(postsContainer).html(html);
        }

        renderRelatedPosts(posts.sort((a, b) => new Date(b.date) - new Date(a.date)), '.related-posts-list')
    }
});


