import '../../js/bootstrap';
import 'jquery';
import 'slick-carousel';
import '../js/posts';
import '../js/tabs';

// global
(function () {
    if (!localStorage.getItem('cookieconsent')) {
        console.log('hello world!');
        // document.body.innerHTML += '\
        // <div class="cookieconsent" style="position:fixed;padding:20px;left:0;bottom:0;background-color:#000;color:#FFF;text-align:center;width:100%;z-index:99999;">\
        // 	<ul class="list-inline mb-0">\
        // 	<li class="list-inline-item"><a href="#" id="i-refuse" class="text-white">I Refuse</a></li>\
        // 	<li class="list-inline-item"><a href="#" id="i-understand">I Understand</a></li>\
        // 	</ul>\
        // 	This site uses cookies. By continuing to use this website, you agree to their use. \
        // </div>\
        // ';

        $('body').append('<div class="cookieconsent" style="position:fixed;padding:20px;left:0;bottom:0;background-color:#000;color:#FFF;text-align:center;width:100%;z-index:99999;">\t\t\t<ul class="list-inline mb-0">\t\t\t<li class="list-inline-item"><a href="#" id="i-refuse" class="text-white">I Refuse</a></li>\t\t\t<li class="list-inline-item"><a href="#" id="i-understand">I Understand</a></li>\t\t\t</ul>\t\t\tThis site uses cookies. By continuing to use this website, you agree to their use. \t\t</div>');
        document.querySelector('.cookieconsent a#i-understand').onclick = function (e) {
            e.preventDefault();
            document.querySelector('.cookieconsent').style.display = 'none';
            localStorage.setItem('cookieconsent', true);
            console.log("User accepted");
        };

        document.querySelector('.cookieconsent a#i-refuse').onclick = function (e) {
            e.preventDefault();
            document.querySelector('.cookieconsent').style.display = 'none';
            localStorage.setItem('cookieconsent', false);
            console.log("User refused, disabling all trackers");

            fbq('set', 'autoConfig', false, 'FB_PIXEL_ID');
            _mfq.push(["stop"]);
            FS.shutdown();
            window['ga-disable-UA-91921428-2'] = true;
        };
    }

    // if (localStorage.getItem('cookieconsent')=="true" || localStorage.getItem('cookieconsent')==true) {
    if (localStorage.getItem('cookieconsent') != "false" && localStorage.getItem('cookieconsent') != false) {

        // Leadboxer
        (function () {
            var leadboxer = document.createElement("script");
            leadboxer.type = "text/javascript"; leadboxer.defer = true;
            leadboxer.src = "//script.leadboxer.com/?dataset=225c17ebbe9018886578712ef3bc0581";
            document.getElementsByTagName("head")[0].appendChild(leadboxer);
        })();

        // Facebook
        // !function (f, b, e, v, n, t, s) {
        //     if (f.fbq) return; n = f.fbq = function () {
        //         n.callMethod ?
        //             n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        //     };
        //     if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        //     n.queue = []; t = b.createElement(e); t.async = !0;
        //     t.src = v; s = b.getElementsByTagName(e)[0];
        //     s.parentNode.insertBefore(t, s)
        // }(window, document, 'script',
        //     'https://connect.facebook.net/en_US/fbevents.js');
        // fbq('init', '356543105550370');
        // fbq('track', 'PageView');

        // Mouseflow
        window._mfq = window._mfq || [];
        (function () {
            var mf = document.createElement("script");
            mf.type = "text/javascript"; mf.defer = true;
            mf.src = "//cdn.mouseflow.com/projects/90635ffb-76d6-4f80-808e-b96837b476c4.js";
            document.getElementsByTagName("head")[0].appendChild(mf);
        })();

        // Fullstory
        window['_fs_debug'] = false;
        window['_fs_host'] = 'fullstory.com';
        window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
        window['_fs_org'] = 'EBT1Y';
        window['_fs_namespace'] = 'FS';
        (function (m, n, e, t, l, o, g, y) {
            if (e in m) { if (m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].'); } return; }
            g = m[e] = function (a, b, s) { g.q ? g.q.push([a, b, s]) : g._api(a, b, s); }; g.q = [];
            o = n.createElement(t); o.async = 1; o.crossOrigin = 'anonymous'; o.src = 'https://' + _fs_script;
            y = n.getElementsByTagName(t)[0]; y.parentNode.insertBefore(o, y);
            g.identify = function (i, v, s) { g(l, { uid: i }, s); if (v) g(l, v, s) }; g.setUserVars = function (v, s) { g(l, v, s) }; g.event = function (i, v, s) { g('event', { n: i, p: v }, s) };
            g.anonymize = function () { g.identify(!!0) };
            g.shutdown = function () { g("rec", !1) }; g.restart = function () { g("rec", !0) };
            g.log = function (a, b) { g("log", [a, b]) };
            g.consent = function (a) { g("consent", !arguments.length || a) };
            g.identifyAccount = function (i, v) { o = 'account'; v = v || {}; v.acctId = i; g(o, v) };
            g.clearUserCookie = function () { };
            g._w = {}; y = 'XMLHttpRequest'; g._w[y] = m[y]; y = 'fetch'; g._w[y] = m[y];
            if (m[y]) m[y] = function () { return g._w[y].apply(this, arguments) };
            g._v = "1.2.0";
        })(window, document, window['_fs_namespace'], 'script', 'user');

        // Google Analytics
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'UA-91921428-2');

        // gtag('event', 'conversion', {'send_to': 'AW-785548946/nCDTCOyulvUBEJKNyvYC'});


        // VisitorQueue
        // function vqTrackId(){return 'f9d345b9-3179-4830-bdd1-9f71d0b98585';} (function(d, e) { var el = d.createElement(e); el.sa = function(an, av){this.setAttribute(an, av); return this;}; el.sa('id', 'vq_tracking').sa('src', '//t.visitorqueue.com/p/tracking.min.js?id='+vqTrackId()).sa('async', 1).sa('data-id', vqTrackId()); d.getElementsByTagName(e)[0].parentNode.appendChild(el); })(document, 'script');

        // Nickelled tours
        (function () {
            var NickelledLaunchers = window.NickelledLaunchers = NickelledLaunchers || { setUser: function (u) { this.userData = u } };
            NickelledLaunchers.userData = {
                appId: "emazing-retailing.com-258332"
                // ,userId: {{USER_IDENTIFIER}}
            };
            (function () {
                var s, f; s = document.createElement("script");
                s.async = true; s.src = "https://cdn.nickelled.com/launchers-2.min.js";
                f = document.getElementsByTagName("script")[0]; f.parentNode.insertBefore(s, f);
            })();
        })();

        // Tawk
        var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = 'https://embed.tawk.to/5f6b12d9f0e7167d0012f2d0/default';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
        })();

        // Linkedin
        // let _linkedin_partner_id = "466092";
        // window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        // window._linkedin_data_partner_ids.push(_linkedin_partner_id);

        (function (l) {
            if (!l) {
                window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
                window.lintrk.q = []
            }
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript"; b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);
        })(window.lintrk);
    }

})();

let acc = document.getElementsByClassName("accordion");

for (let i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    });
}

$('.quotes-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    speed: 300,
    autoplay: true,
});

document.addEventListener("DOMContentLoaded", function () {
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;

    if (document.querySelector('.img-methodologies')) {
        function initMethodologiesAnimation() {
            let element = document.querySelector('.img-methodologies');
            let position = element.getBoundingClientRect();
            if ((position.bottom < windowHeight * 0.8 || position.bottom < 0) && windowWidth >= 768) {
                element.classList.add("visible");
            } else if ((position.top < windowHeight * 0.5 || position.top < 0) && windowWidth <= 768) {
                element.classList.add("visible");
            } else {
                if (!element.classList.contains('anim-hide')) {
                    element.classList.remove("visible");
                }
            }
        }
        window.addEventListener("scroll", initMethodologiesAnimation);
        window.addEventListener("resize", initMethodologiesAnimation);
        window.addEventListener("load", initMethodologiesAnimation);

        initMethodologiesAnimation()
    }

    if (document.querySelector('.emazing-atom')) {
        function initAtomAnimation() {
            let element = document.querySelector('.emazing-atom');
            let position = element.getBoundingClientRect();
            if (position.top < windowHeight * 0.5 || position.top < 0) {
                element.classList.add("visible");
                element.classList.remove("hide");
            } else {
                element.classList.remove("visible");
                element.classList.add("hide");
            }
        }
        window.addEventListener("scroll", initAtomAnimation);
        window.addEventListener("resize", initAtomAnimation);
        window.addEventListener("load", initAtomAnimation);

        initAtomAnimation();
    }
});
